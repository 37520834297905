<script setup lang="ts">
import { Background } from '@vue-flow/background';
import { VueFlow } from '@vue-flow/core';
</script>
<template>
  <div class="w-full h-full relative">
    <VueFlow
      id="auth"
      :fit-view-on-init="true"
      :default-zoom="1.5"
      :min-zoom="0.3"
      :max-zoom="2.5"
      :snap-to-grid="true"
      :snap-grid="[20, 20]"
      :connect-on-click="false"
      :edges-updatable="true"
      :edge-updater-radius="30"
    >
      <Background pattern-color="#787893" :gap="20" :size="1.5" />
    </VueFlow>
    <div class="flex grow absolute inset-0 justify-center">
      <UCard
        :ui="{
          shadow: 'shadow-lg',
          rounded: 'rounded-3xl',
        }"
        class="w-full max-w-md p-3 pb-5"
      >
        <div class="flex flex-col gap-5">
          <app-logo size="l" class="mb-4" />

          <slot />
        </div>
      </UCard>
    </div>
  </div>
</template>
